import request from '@/utils/request'

export function getDigitalBusgrp(formFile) {
  return request({
    url: "/api/ajax/digital/getDigitalBusgrp.json",
    method: 'post',
    data: formFile,
  })
}

export function getDigitalPerson(formFile) {
  return request({
    url: "/api/ajax/digital/getDigitalPerson.json",
    method: 'post',
    data: formFile,
  })
}

export function getDigitalDetailTitle(formFile) {
  return request({
    url: "/api/ajax/digital/getDigitalDetailTitle.json",
    method: 'post',
    data: formFile,
  })
}

export function getDigitalDetailContent(formFile) {
  return request({
    url: "/api/ajax/digital/getDigitalDetailContent.json",
    method: 'post',
    data: formFile,
  })
}

export function saveDigitalGrow(formFile) {
  return request({
    url: "/api/ajax/digital/saveDigitalGrow.json",
    method: 'post',
    data: formFile,
  })
}

export function getDigitalGrow(formFile) {
  return request({
    url: "/api/ajax/digital/getDigitalGrow.json",
    method: 'post',
    data: formFile,
  })
}

export function getDigitalRoot(formFile) {
  return request({
    url: "/api/ajax/digital/getDigitalRoot.json",
    method: 'post',
    data: formFile,
  })
}

export function sendToLeader(formFile) {
  return request({
    url: "/api/ajax/digital/sendToLeader.json",
    method: 'post',
    data: formFile,
  })
}

export function getDigitalRecord(formFile) {
  return request({
    url: "/api/ajax/digital/getDigitalRecord.json",
    method: 'post',
    data: formFile,
  })
}









