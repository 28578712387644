<template>
  <div class="dialog-card" v-if="show">
    <van-dialog
        v-model="show"
        :title="data.text"
        show-cancel-button
        :beforeClose="chargeBtn"
        confirm-button-text='确定'
        cancel-button-text='取消'
    >
      <van-field
          v-model="fieldValue"
          @input = "onDescribe"
          rows="7"
          autosize
          label=""
          type="textarea"
          maxlength="500"
          placeholder="请填写"
          show-word-limit
          :readonly="data.edit"
      />
    </van-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import {Row, Col, Cell} from 'vant'

Vue.use(Row).use(Col).use(Cell)
export default {
  name: "DialogCard",
  props: {
    data: {type: Object},
    show: {type: Boolean},
    fieldValue: {type: String},
  },
  data() {
    return {
    }
  },
  methods: {
    chargeBtn(action, done) {
      if (action === 'cancel') {
        this.$emit('success', false)
      } else if (action === 'confirm') {
        this.$emit('success', false)
        var edit = {}
        edit.text = this.data.text
        edit.value = this.fieldValue
        this.$emit('editVal', edit)
      }
    },
    onDescribe(){
      console.log(this.data.value)
    },
  }
}
</script>

<style scoped>

</style>